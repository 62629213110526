<div class="row mt-2" *ngIf="title">
    <div class="col">
        <h2>{{ title }}</h2>
    </div>
</div>

<div class="row mt-2" *ngIf="documents && documents.length > 0 && !showBottom">
    <div class="col">
        <table class="grid-table" *ngIf="!singleFileOnly">
            <tr>
                <th>{{ 'Common.Upload.FileName' | translate }}</th>
                <th class="icon-column"></th>
            </tr>
            <tr *ngFor="let document of documents">
                <td>{{ document.filename }}</td>
                <td class="text-right icon-column">
                    <mat-icon *ngIf="document.id" color="primary" (click)="downloadDocument(document)"
                        class="cursorpointer">cloud_download</mat-icon>
                    <mat-icon *ngIf="canEdit" color="primary" (click)="removeDocument(document)" class="ms-3 cursorpointer">
                        delete
                    </mat-icon>
                </td>
            </tr>
        </table>
        <div *ngIf="singleFileOnly">
            <div class="row" *ngFor="let document of documents">
                <div class="col">
                    <span class="me-4">Hochgeladene Datei: </span>
                    <a (click)="downloadDocument(document)" class="cursorpointer" *ngIf="document.id">{{ document.filename }}</a>
                    <span *ngIf="!document.id">{{ document.filename }}</span>
                    <mat-icon *ngIf="canEdit" color="primary" (click)="removeDocument(document)" class="ms-2 cursorpointer align-text-bottom">
                        delete
                    </mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row mt-2" *ngIf="canEdit">
    <div class="col">
        <ngx-file-drop [class.disabled-section]="this.disabled ?? false"
            dropZoneLabel="{{ 'Common.Upload.UploadLabel' | translate }}" (onFileDrop)="onDrop($event)"
            dropZoneClassName="dropzone" contentClassName="dropzone-content" [multiple]="multiple ?? false"
            [disabled]="this.disabled ?? false">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <div (click)="openFileSelector()" *ngIf="!disabled">
                    <mat-icon color="primary">cloud_upload</mat-icon><br />
                    {{ 'Common.Upload.UploadLabel' | translate }}
                </div>

                <div *ngIf="disabled" class="disabled-section">
                    <mat-icon color="primary">cloud_upload</mat-icon><br />
                    {{ 'Common.Upload.UploadLabel' | translate }}
                </div>
            </ng-template>
        </ngx-file-drop>
        <p *ngIf="allowedFileExtensions">
            {{ 'Common.Upload.AllowedFiles' | translate }} {{ allowedFileExtensions.join(', ') }}
        </p>
    </div>
</div>

<div class="row mt-3" *ngIf="fileErrorTranslationCode">
    <div class="col">
        <mat-error>
            {{ fileErrorTranslationCode | translate:transValue }}
        </mat-error>
    </div>
</div>

<div class="row mt-2" *ngIf="documents && documents.length > 0 && showBottom">
    <div class="col">
        <table class="grid-table">
            <tr>
                <th><b>{{ 'Common.Upload.FileName' | translate }}</b></th>
                <th class="icon-column"></th>
            </tr>
            <tr *ngFor="let document of documents">
                <td class="cell-overflow">{{ document.filename }}</td>
                <td class="text-right icon-column">
                    <mat-icon *ngIf="document.id" color="primary" (click)="downloadDocument(document)"
                        class="cursorpointer">cloud_download</mat-icon>
                    <mat-icon *ngIf="canEdit" color="primary" (click)="removeDocument(document)" class="ms-3 cursorpointer">delete
                    </mat-icon>
                </td>
            </tr>
        </table>
    </div>
</div>
